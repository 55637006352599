import React, { createContext, ReactNode, useCallback, useContext, useEffect, useReducer, useState } from 'react'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { useSelector } from 'react-redux'
import moment from 'moment'

import AvailabilityQuery, { DayAvailability, FlexibleAvailabilityRequestTypes } from 'src/graphql/Availability'
import formatter from 'src/utils/formatter'
import { getDefaultEndTime, getDefaultStartTime } from './dateHelpers'
import { availabilityReducer, State } from './availabilityReducer'
import { AvailabilityScheduleType, submitDayAvailability } from './helpers'
import { bugsnagActionBreadcrumb } from 'src/utils/bugsnag'
import {
  resetStateAction,
  updateAvailabilityListAction,
  updateDayAvailabilityAction,
  updateWorkingHoursAction,
} from './availabilityActions'
import { TimeScheduleTypes } from 'src/graphql/sharableTypes'

type AvailabilityProviderProps = { children: ReactNode }

type AvailabilityContextTypes = {
  availabilityFetchingError: ApolloError | undefined
  getMonthData: (selectedMonthDay: string) => void
  isSubmitting: boolean
  loadingAvailability: boolean
  resetState: () => void
  selectedDay: string
  setSelectedDay: (day: string) => void
  state: State
  updateDayAvailability: (props: { available: boolean }) => void
  updateDayScheduleType: (timeScheduleType: TimeScheduleTypes) => void
  updateDayWorkingHours: (updatedWorkingHours: AvailabilityScheduleType) => void
}

const AvailabilityStateContext = createContext<AvailabilityContextTypes | undefined>(undefined)

export const useAvailabilityValues = () => {
  const getToday = () => formatter.apiFormat()

  const [state, dispatch] = useReducer(availabilityReducer, { availabilityList: {} })
  const [selectedDay, setSelectedDay] = useState<string>(getToday())

  const [isSubmitting, setIsSubmitting] = useState(false)

  //@ts-ignore
  const id = useSelector(appState => appState.user?.id)

  useEffect(() => {
    getMonthData(getToday())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [getAvailability, { loading: loadingAvailability, error: availabilityFetchingError }] =
    useLazyQuery<FlexibleAvailabilityRequestTypes>(AvailabilityQuery, {
      fetchPolicy: 'cache-and-network',
      onCompleted: response => dispatch(updateAvailabilityListAction(response)),
    })

  const getMonthData = useCallback((selectedMonthDay: string) => {
    const startDate = moment().isSame(selectedMonthDay, 'month')
      ? formatter.apiFormat()
      : formatter.getFirstMonthDay(selectedMonthDay)
    const endDate = formatter.getLastMonthDay(selectedMonthDay)

    getAvailability({
      variables: {
        endDate,
        startDate,
        userId: id,
        confirmed: true,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmitSuccess = () => setIsSubmitting(false)
  const onSubmitError = () => {
    setIsSubmitting(false)
    getMonthData(selectedDay)
  }

  const updateDayAvailability = ({ available }: { available: boolean }) => {
    const dataToUpload = {
      date: selectedDay,
      dayScheduleEntries: [{ timeScheduleType: 'full_day', available }] as Partial<DayAvailability>[],
    }
    bugsnagActionBreadcrumb(`update day availability`, { dataToUpload })
    dispatch(updateDayAvailabilityAction({ date: selectedDay, available }))

    return submitDayAvailability(id, dataToUpload, onSubmitSuccess, onSubmitError)
  }

  const updateDayScheduleType = (timeScheduleType: TimeScheduleTypes) => {
    const isHourly = timeScheduleType === 'hourly'
    const scheduleType = isHourly
      ? {
          available: true,
          endTime: getDefaultEndTime(selectedDay),
          startTime: getDefaultStartTime(selectedDay),
          timeScheduleType,
        }
      : { timeScheduleType, available: true }

    const dataToUpload = {
      date: selectedDay,
      dayScheduleEntries: [scheduleType],
    }

    bugsnagActionBreadcrumb(`update day schedule type`, { dataToUpload })
    dispatch(updateWorkingHoursAction({ scheduleType, day: selectedDay }))

    return submitDayAvailability(id, dataToUpload, onSubmitSuccess, onSubmitError)
  }

  const updateDayWorkingHours = (updatedWorkingHours: AvailabilityScheduleType) => {
    const dataToUpload = {
      date: selectedDay,
      dayScheduleEntries: [{ ...updatedWorkingHours, available: true }],
    }
    bugsnagActionBreadcrumb(`update day working hours`, { dataToUpload })
    dispatch(updateWorkingHoursAction({ scheduleType: updatedWorkingHours, day: selectedDay }))

    return submitDayAvailability(id, dataToUpload, onSubmitSuccess, onSubmitError)
  }

  const resetState = async () => {
    bugsnagActionBreadcrumb('reset schedule view - onFocus/onForeground effect')
    await dispatch(resetStateAction())
    getMonthData(getToday())
    setSelectedDay(getToday())
  }

  return {
    availabilityFetchingError,
    getMonthData,
    isSubmitting,
    loadingAvailability,
    selectedDay,
    setSelectedDay,
    state,
    updateDayAvailability,
    updateDayScheduleType,
    updateDayWorkingHours,
    resetState,
  }
}

const AvailabilityProvider = ({ children }: AvailabilityProviderProps) => {
  const value = useAvailabilityValues()
  return <AvailabilityStateContext.Provider value={value}>{children}</AvailabilityStateContext.Provider>
}

const useAvailabilitySchedule = () => {
  const context = useContext(AvailabilityStateContext)
  if (context === undefined) {
    throw new Error('useAvailabilitySchedule must be used within a AvailabilityProvider')
  }
  return context
}

export { AvailabilityProvider, useAvailabilitySchedule, AvailabilityStateContext }
