import { Dimensions, StyleSheet } from 'react-native'

import { padding, colorsNew, typography, paddingNew } from 'src/styles'

export const MAX_HEIGHT = Dimensions.get('window').height * 0.8

export default StyleSheet.create({
  alert: {
    marginBottom: paddingNew.sm,
    marginTop: paddingNew.lg - paddingNew.xs,
  },
  applicantGotoRecruitmentButton: {
    marginTop: paddingNew.base,
  },
  emptyMessage: {
    ...typography.body2,
    color: colorsNew.redesign.text.secondary,
  },
  header: {
    ...typography.h7,
    color: colorsNew.redesign.text.primary,
  },
  loading: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: padding.lg,
  },
  requestAlert: {
    marginVertical: paddingNew.sm,
  },
  unknownAvailabilityAlert: {
    marginTop: paddingNew.lg,
  },
})
