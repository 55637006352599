import { FlexibleAvailabilityRequestTypes } from 'src/graphql/Availability'
import {
  AvailabilityScheduleType,
  ConvertedAvailabilityDay,
  ConvertedAvailabilityList,
  getConvertedAvailabilityList,
} from './helpers'
import { UPDATE_AVAILABILITY_LIST, UPDATE_DAY_AVAILABILITY, UPDATE_DAY_WORKING_HOURS, RESET_STATE } from './types'

export type Action =
  | { type: 'UPDATE_AVAILABILITY_LIST'; data: FlexibleAvailabilityRequestTypes }
  | { type: 'UPDATE_DAY_AVAILABILITY'; data: { date: string; available: boolean } }
  | { type: 'UPDATE_DAY_WORKING_HOURS'; data: { scheduleType: AvailabilityScheduleType; day: string } }
  | { type: 'RESET_STATE' }

export type State = { availabilityList: ConvertedAvailabilityList }

export const availabilityReducer = (state: State, action: Action) => {
  switch (action.type) {
    case UPDATE_AVAILABILITY_LIST: {
      const convertedData = getConvertedAvailabilityList(action.data)
      return {
        availabilityList: { ...state.availabilityList, ...convertedData },
      }
    }

    case UPDATE_DAY_AVAILABILITY: {
      const { date, available } = action.data
      const selectedDay = state.availabilityList[date]
      const updatedDay = {
        ...selectedDay,
        inactive: !available,
        scheduleType: selectedDay.inactive ? { timeScheduleType: 'full_day' } : {},
        unknown: available == null,
      } as ConvertedAvailabilityDay

      return {
        availabilityList: { ...state.availabilityList, [date]: updatedDay },
      }
    }

    case UPDATE_DAY_WORKING_HOURS: {
      const selectedDay = state.availabilityList[action.data.day]
      const updatedDay = {
        ...selectedDay,
        scheduleType: action.data.scheduleType,
      }
      return {
        availabilityList: { ...state.availabilityList, [action.data.day]: updatedDay },
      }
    }

    case RESET_STATE: {
      return { availabilityList: {} }
    }
  }
}
