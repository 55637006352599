export type FeatureFlagsT = {
  expressInTheUK: boolean
  release_refer_a_friend_240828: boolean
  permission_archive_availability_page_250121: boolean
  release_unavailable_educator_note_250121: boolean
  release_schedule_unknown_state_250127: boolean
  release_keep_availability_updated_alert_250127: boolean
  permission_archive_edit_address_screen_250131: boolean
}

export type FeatureFlagStateT = {
  featureFlags: FeatureFlagsT
}

export enum FeatureFlags {
  EXPRESS_UK_FEATURE_TOGGLE = 'expressInTheUK',
  REFER_A_FRIEND_FEATURE_TOGGLE = 'release_refer_a_friend_240828',
  PERMISSION_ARCHIVE_AVAILABILITY_PAGE_250121 = 'permission_archive_availability_page_250121',
  RELEASE_UNAVAILABLE_EDUCATOR_NOTE_250121 = 'release_unavailable_educator_note_250121',
  RELEASE_SCHEDULE_UNKNOWN_STATE_250127 = 'release_schedule_unknown_state_250127',
  RELEASE_KEEP_AVAILABILITY_UPDATED_ALERT_250127 = 'release_keep_availability_updated_alert_250127',
  PERMISSION_ARCHIVE_EDIT_ADDRESS_SCREEN_250131 = 'permission_archive_edit_address_screen_250131',
}

export const flagKeys = [
  FeatureFlags.REFER_A_FRIEND_FEATURE_TOGGLE,
  FeatureFlags.EXPRESS_UK_FEATURE_TOGGLE,
  FeatureFlags.PERMISSION_ARCHIVE_AVAILABILITY_PAGE_250121,
  FeatureFlags.RELEASE_UNAVAILABLE_EDUCATOR_NOTE_250121,
  FeatureFlags.RELEASE_SCHEDULE_UNKNOWN_STATE_250127,
  FeatureFlags.RELEASE_KEEP_AVAILABILITY_UPDATED_ALERT_250127,
  FeatureFlags.PERMISSION_ARCHIVE_EDIT_ADDRESS_SCREEN_250131,
]
