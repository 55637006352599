import React from 'react'
import { Text, View } from 'react-native'

import { EditLocationIcon } from 'src/icons'
import { iconButtonSize, iconButtonVariant } from 'src/components/Buttons/IconButton/variants'
import { useFeatureFlagsContext } from 'src/hooks/useFeatureFlags'
import IconButton from 'src/components/Buttons/IconButton'

import styles from './styles'

type Props = {
  city: string
  navigateToEdit: () => void
  street: string
  zip: string
}

const AddressRow = ({ city, zip, street, navigateToEdit }: Props) => {
  const { featureFlags } = useFeatureFlagsContext()
  return (
    <View style={styles.addressRowContainer}>
      <Text style={styles.addressInfo}>
        {street}, {zip}, {city}
      </Text>
      {!featureFlags.permission_archive_edit_address_screen_250131 && (
        <IconButton
          Icon={EditLocationIcon}
          onPress={navigateToEdit}
          size={iconButtonSize.lg}
          variant={iconButtonVariant.filled}
        />
      )}
    </View>
  )
}

export default AddressRow
