import { useMemo } from 'react'
import { useAppSelector } from './reduxHooks'
import moment from 'moment'
import formatter from 'src/utils/formatter'
import { useQuery } from '@apollo/client'
import AvailabilityQuery, { FlexibleAvailabilityRequestTypes } from 'src/graphql/Availability'

const useAvailabilityData = () => {
  const userId = useAppSelector(state => state.user?.id)

  const [startDate, endDate] = useMemo(() => {
    const today = moment()
    // Start today unless today is a weekend, in which case start on next Monday
    const startDate = today.isoWeekday() <= 5 ? today : today.startOf('isoWeek').add(1, 'week')
    // End on next Friday
    const endDate = moment(startDate).endOf('isoWeek').add(-2, 'days')

    return [startDate, endDate].map(o => formatter.apiFormat(o.toDate()))
  }, [])

  return useQuery<FlexibleAvailabilityRequestTypes>(AvailabilityQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      endDate,
      startDate,
      userId,
      confirmed: true,
    },
  })
}

export default useAvailabilityData
