import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  container: {
    marginTop: paddingNew.xs,
    marginHorizontal: paddingNew.base,
  },
  header: StyleSheet.flatten([
    typography.body2,
    {
      color: colorsNew.redesign.text.primary,
    },
  ]),
  actions: {
    marginTop: paddingNew.sm,
    marginHorizontal: paddingNew.md,
    gap: paddingNew.sm,
  },
})
