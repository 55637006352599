import { StyleSheet } from 'react-native'

import { colorsNew, paddingNew, typography } from 'src/styles'

export default StyleSheet.create({
  container: {
    marginTop: paddingNew.sm,
  },
  header: {
    alignItems: 'center',
    flexDirection: 'row',
    height: 38,
    justifyContent: 'space-between',
    marginHorizontal: paddingNew.base,
  },
  headerText: StyleSheet.flatten([
    typography.h7,
    {
      color: colorsNew.redesign.text.primary,
      flex: 1,
      textAlignVertical: 'center',
      textTransform: 'capitalize',
    },
  ]),
  headerInactive: {
    color: colorsNew.redesign.text.secondary,
  },
  unavailableNoteContainer: {
    marginHorizontal: paddingNew.base,
    marginVertical: paddingNew.xs,
  },
  unavailableNoteTitle: StyleSheet.flatten([
    typography.overline,
    {
      color: colorsNew.redesign.text.secondary,
    },
  ]),
  unavailableNoteDescription: StyleSheet.flatten([
    typography.caption,
    {
      color: colorsNew.redesign.text.secondary,
    },
  ]),
  radioButtonsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: paddingNew.sm,
  },
})
