import { Text, View } from 'react-native'
import styles from './styles'
import ButtonNew from 'src/components/Buttons/ButtonNew/ButtonNew'
import translations, { translate } from 'src/utils/translations/translations'
import { buttonVariants } from 'src/components/Buttons/ButtonNew'

const UnknownForm = ({ onSubmit }: { onSubmit: (props: { available: boolean }) => void }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.header}>{translate(translations.confirmIfYoureAvailableOrNot)}</Text>
      <View style={styles.actions}>
        <ButtonNew
          title={translate(translations.iWantToWork)}
          variant={buttonVariants.containedDefault}
          onPress={() => onSubmit({ available: true })}
        />
        <ButtonNew
          title={translate(translations.noAvailable)}
          variant={buttonVariants.outlinedDefault}
          onPress={() => onSubmit({ available: false })}
        />
      </View>
    </View>
  )
}

export default UnknownForm
