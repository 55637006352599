import type { FeatureFlagStateT } from './types'

export const initialFeatureFlagState: FeatureFlagStateT = {
  featureFlags: {
    expressInTheUK: false,
    release_refer_a_friend_240828: false,
    permission_archive_availability_page_250121: false,
    release_unavailable_educator_note_250121: false,
    release_schedule_unknown_state_250127: false,
    release_keep_availability_updated_alert_250127: false,
    permission_archive_edit_address_screen_250131: false,
  },
}
