import React, { ComponentType } from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import { DayProps } from 'react-native-calendars/src/calendar/day'
import { DateData } from 'react-native-calendars'

import { LineDiagonal } from 'src/icons'
import { colorsNew } from 'src/styles'
import styles from './styles'
import { useFeatureFlagsContext } from 'src/hooks/useFeatureFlags'

interface Props extends Omit<DayProps, 'date'> {
  date: DateData
}

const Day = ({ date, state, marking, onPress }: Props) => {
  const { featureFlags } = useFeatureFlagsContext()
  const unknown = featureFlags.release_schedule_unknown_state_250127 && marking?.unknown

  return (
    <View style={styles.dayContainer}>
      <TouchableOpacity
        //@ts-ignore
        onPress={() => onPress(date)}
        style={StyleSheet.flatten([
          styles.dayCircle,
          marking?.today && styles.dayCircleToday,
          unknown && styles.dayCircleUnknown,
          unknown && marking?.today && styles.dayCircleUnknownToday,
          marking?.inactive && styles.dayCircleInactive,
          marking?.selected && styles.dayCircleSelected,
          state === 'disabled' && styles.dayCircleDisabled,
        ])}
        testID="day-button"
      >
        <Text
          style={StyleSheet.flatten([
            styles.dayText,
            marking?.selected && styles.dayTextSelected,
            state === 'disabled' && styles.dayTextDisabled,
          ])}
          testID="day-text"
        >
          {date.day}
        </Text>
        {marking?.inactive ? (
          <View style={styles.diagonalLine} testID="day-inactive-mark">
            <LineDiagonal
              fill={marking?.selected ? colorsNew.redesign.secondary.contrast : colorsNew.redesign.secondary.dark}
              size={24}
            />
          </View>
        ) : null}
      </TouchableOpacity>
      <View
        style={StyleSheet.flatten([styles.dot, marking?.marked ? styles.activeDot : null])}
        testID="day-booking-mark"
      />
    </View>
  )
}

export default Day as ComponentType<DayProps & { date?: DateData | undefined }>
