import { FlexibleAvailabilityRequestTypes } from 'src/graphql/Availability'
import { AvailabilityScheduleType } from './helpers'
import { UPDATE_AVAILABILITY_LIST, UPDATE_DAY_AVAILABILITY, UPDATE_DAY_WORKING_HOURS, RESET_STATE } from './types'

export const updateDayAvailabilityAction = (data: { date: string; available: boolean }) =>
  ({
    type: UPDATE_DAY_AVAILABILITY,
    data,
  } as const)

export const updateAvailabilityListAction = (data: FlexibleAvailabilityRequestTypes) =>
  ({
    type: UPDATE_AVAILABILITY_LIST,
    data,
  } as const)

export const updateWorkingHoursAction = (data: { scheduleType: AvailabilityScheduleType; day: string }) =>
  ({
    type: UPDATE_DAY_WORKING_HOURS,
    data,
  } as const)

export const resetStateAction = () => ({ type: RESET_STATE } as const)
